export const environment = {
  production: false,
  hmr: false,
     apiURL: 'https://karangue.cnaas.sn/api/',
    //apiURL: 'http://192.168.1.48:9300/api/',
     max: 1000000,
     apiURLPreuve: 'https://karangue.cnaas.sn/api/download-preuve?fileName=',
     apiURL1: 'https://karangue.cnaas.sn/apit/',
     offset:0,
     appUrlAppelCotisation:"https://karangue.cnaas.sn/",
     appUrlPreuve:"https://karangue.cnaas.sn/",
     apiUrlContrat: 'http://145.239.144.190:8081/contrat/generer.php?id=',
};

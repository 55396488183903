import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs'; 
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { RechercheService } from 'app/_services/recherche.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
  userConnecte = null;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  userinfos: any;
  dashboardVisible:boolean = false;
  dashboardPartenaireVisible:boolean = false;
  dashboardDirecteurVisible:boolean = false;
  dashboardReseauVisible:boolean = false;
  dashboardAgentVisible:boolean = false;
  // Private
  private _unsubscribeAll: Subject<any>;

  // Breadcrumb
  messagesBreadcumb: any[] = [];
  subscription: Subscription;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
     private _fuseNavigationService: FuseNavigationService,
    private rechercheservice: RechercheService,
    public router: Router // private breadcrumbService: BreadcrumbService,
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];
    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];
    this.navigation = navigation;

    this._unsubscribeAll = new Subject();
  }
  ngOnInit(): void{
    this.userConnecte = JSON.parse(localStorage.getItem('inclusiveGuarantee'));
       if(this.userConnecte.roles.indexOf('ROLE_PARTENAIRE') != -1 || this.userConnecte.roles.indexOf('ROLE_ADMIN_PARTENAIRE') != -1){
         this.dashboardPartenaireVisible = true;
       }else if(this.userConnecte.roles.indexOf('ROLE_ADMINISTRATEUR') != -1){
        this.dashboardVisible = true;
       }else if(this.userConnecte.roles.indexOf('DIRECTEUR') != -1){
        this.dashboardDirecteurVisible = true;
       }else if(this.userConnecte.roles.indexOf('RESEAU') != -1){
        this.dashboardReseauVisible = true;
       }else if(this.userConnecte.roles.indexOf('ROLE_AGENCE') != -1){
        this.dashboardAgentVisible = true;
       }
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(settings => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
    if (this.userConnecte.infos.username != '' && this.userConnecte.infos.username !=null && this.userConnecte.infos.username!=undefined) {
      this.userByUsername();
    }
  }

  userByUsername(){
    if (this.userConnecte.infos.username != '' && this.userConnecte.infos.username !=null && this.userConnecte.infos.username!=undefined) {
     let username = this.userConnecte.infos.username;
      let data = {
       'username':username
      } 
      this.rechercheservice.userByUsername(data).subscribe((resp) => {
          if(resp['responseCode'] == 200){
          this.userinfos = resp['data'];
      }}, error => {
      });
   }
}

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
  }
  setLanguage(lang): void {
    this.selectedLanguage = lang;
    this._translateService.use(lang.id);
  }
  deconnexion(): void {
    localStorage.removeItem('inclusiveGuarantee');
    localStorage.removeItem('email'); 
    localStorage.removeItem('codeRecu');
    localStorage.removeItem('user');
    localStorage.removeItem('max');
    localStorage.removeItem('offset');
    this._fuseNavigationService.unregister('menu'); 
    this._router.navigate(['./login']);
  }
  changeMDP(): void {
    this._router.navigate(['administration/change-password']);
  }
}
